import { useCallback, useEffect, useMemo, useState } from 'react';
import { ClientFormUser } from '../../models/ClientFormUser';
import { ClientFormUserRole, ClientFormUserRoleKeys, ClientFormUserRoleValues } from '../../models/ClientFormUserRoles';
import DropdownSelect, { DropdownSize } from '../shared/form-control/DropdownSelect';
import { Option } from '../Option';
import { roleOptions } from './RoleOptions';
import { useTranslation } from 'react-i18next';
import { FCWithChildren } from '../../types/FCWithChildren';
import InviteOrSearchContent from '../user/InviteOrSearchContent';
import { useRecoilValue } from 'recoil';
import { Roles } from '../../models/Role';
import UserInvitation from '../../models/UserInvitation';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import ClientFormRoleGroup from './ClientFormRoleGroup';
import { SearchInput } from '../shared/form-control/SearchInput';
import { InputStyle } from '../shared/form-control/Input';
import useDebounce from '../../hooks/useDebounce';
import UsersGroup2Icon from '../shared/icon/UsersGroup2Icon';
import Checkbox from '../shared/form-control/Checkbox';
import InfoIcon from '../shared/icon/InfoIcon';
import Tooltip from '../shared/Tooltip';

type AccessControlProps = {
  users: ClientFormUser[];
  onUsersChange: (users: ClientFormUser[]) => void;
  requireValidator?: boolean;
  requireApprover?: boolean;
  requireOwner?: boolean;
  disableAdd?: boolean;
  creatorRole?: ClientFormUserRoleValues;
  onCreatorRoleChange?: (role: ClientFormUserRoleValues | null) => void;
  formStepId?: string;
  inviteMode?: boolean;
  onUserInviteChange?: (user: UserInvitation) => void;
  onUserInviteValid?: (isValid: boolean) => void;
  excludeOwnerRole?: boolean;
};

const noAccessValue = -1;
const userGroupOrder: ClientFormUserRoleValues[] = [
  ClientFormUserRole.Owner,
  ClientFormUserRole.Validator,
  ClientFormUserRole.Approver,
  ClientFormUserRole.Contributor,
  ClientFormUserRole.Viewer,
];

const AccessControl: FCWithChildren<AccessControlProps> = (props) => {
  const {
    users,
    onUsersChange,
    requireValidator,
    requireApprover,
    requireOwner,
    disableAdd = false,
    creatorRole,
    onCreatorRoleChange,
    formStepId,
    inviteMode,
    onUserInviteChange,
    onUserInviteValid,
    excludeOwnerRole,
  } = props;
  const { t } = useTranslation(['common']);
  const currentClient = useRecoilValue(currentClientAtom);
  const [formRoleOptions, setFormRoleOptions] = useState<Option<string, string | number>[]>([]);
  const [role, setRole] = useState<ClientFormUserRoleValues>(ClientFormUserRole.Viewer);
  const [userSearch, setUserSearch] = useState('');
  const userSearchDebounced = useDebounce(userSearch, 100);
  const [validatorsHaveOrder, setValidatorsHaveOrder] = useState(() =>
    users.some((x) => x.role === ClientFormUserRole.Validator && typeof x.sortOrder === 'number'),
  );
  const [approversHaveOrder, setApproversHaveOrder] = useState(() =>
    users.some((x) => x.role === ClientFormUserRole.Approver && typeof x.sortOrder === 'number'),
  );

  const filteredRoles = useMemo(() => {
    return formRoleOptions
      .filter((role) => {
        // Filter out Owner form role if already assigned
        if (role.value === ClientFormUserRole.Owner) {
          if (excludeOwnerRole || users.find((x) => x.role === ClientFormUserRole.Owner) || creatorRole === ClientFormUserRole.Owner) {
            return false;
          }
        }
        return true;
      })
      .filter((role) => {
        return !requireApprover ? role.value !== ClientFormUserRole.Approver : true;
      })
      .filter((role) => {
        return !requireValidator ? role.value !== ClientFormUserRole.Validator : true;
      })
      .filter((role) => {
        const stepRoles = [ClientFormUserRole.Contributor, ClientFormUserRole.Viewer, noAccessValue];
        if (formStepId && requireApprover) {
          stepRoles.push(ClientFormUserRole.Approver);
        }
        return !formStepId ? true : stepRoles.indexOf(role.value as number) > -1;
      })
      .map((role) => ({
        id: role.id,
        value: role.value,
        text: role.value === noAccessValue ? role.text : t(ClientFormUserRoleKeys[role.value as ClientFormUserRoleValues]),
      }));
  }, [formRoleOptions, excludeOwnerRole, users, creatorRole, requireApprover, requireValidator, formStepId, t]);

  const onRoleChange = (value: ClientFormUserRoleValues) => {
    setRole(value);
  };

  useEffect(() => {
    setFormRoleOptions([
      ...roleOptions.map((x) => {
        return {
          id: x.id,
          text: t(ClientFormUserRoleKeys[x.value as ClientFormUserRoleValues]),
          value: x.value,
        };
      }),
      { id: noAccessValue.toString(), value: noAccessValue, text: t('common:form-role.no-access') },
    ]);
  }, [t]);

  const findRole = (value: number) => {
    const role = formRoleOptions.find((role) => role.value === value);
    if (!role) {
      return undefined;
    }

    return {
      id: role.id,
      value: role.value,
      text: role.text,
    };
  };

  const inviteRoleFilter = useCallback((role: Option<string, string>) => {
    const roles = [Roles.TeamLead, Roles.TeamMember, Roles.Management, Roles.Employee, Roles.ExternalContributor];
    return roles.indexOf(role.value as Roles) > -1;
  }, []);

  const requiredRoles = useMemo(() => {
    const roles = [];
    if (requireApprover) {
      roles.push(ClientFormUserRole.Approver);
    }
    if (requireValidator) {
      roles.push(ClientFormUserRole.Validator);
    }
    if (requireOwner) {
      roles.push(ClientFormUserRole.Owner);
    }
    return roles;
  }, [requireApprover, requireOwner, requireValidator]);

  const onUserChange = useCallback(
    (user: ClientFormUser) => {
      const index = users.findIndex((x) => x.id === user.id);
      if (index === -1) return;

      const updatedUsers = [...users];
      updatedUsers[index] = user;

      const roleIndexes: Record<number, number> = {};
      onUsersChange(
        updatedUsers.map((x) => {
          roleIndexes[x.role] ??= 0;

          return { ...x, sortOrder: x.requiresAction ? roleIndexes[x.role]++ : null };
        }),
      );
    },
    [onUsersChange, users],
  );

  const owner = useMemo(() => users.find((x) => x.role === ClientFormUserRole.Owner), [users]);
  const ownerHasFinalSay = useMemo(() => !!owner?.requiresAction, [owner]);
  const ownerFooter = useMemo(
    () => (
      <Checkbox
        disabled={!owner}
        value={ownerHasFinalSay}
        onChange={(value) => onUserChange({ ...owner!, requiresAction: value })}
        label={
          <span>
            {t('permissions-modal.group.require-final-acknowledgement')}{' '}
            <Tooltip text={t('permissions-modal.group.require-final-acknowledgement-tooltip')}>
              {(tooltip) => (
                <span {...tooltip}>
                  <InfoIcon className="h-5 w-5 text-black" />
                </span>
              )}
            </Tooltip>
          </span>
        }
      />
    ),
    [onUserChange, owner, ownerHasFinalSay, t],
  );

  const validateOrderDisabled = useMemo(() => users.filter((x) => x.role === ClientFormUserRole.Validator && x.requiresAction).length < 2, [users]);
  const validatorFooter = useMemo(
    () => (
      <Checkbox
        disabled={validateOrderDisabled}
        value={validatorsHaveOrder}
        onChange={(value) => setValidatorsHaveOrder(value)}
        label={
          <span>
            {t('permissions-modal.group.require-ordering-reviewers')}{' '}
            <Tooltip text={t('permissions-modal.group.require-ordering-reviewers-tooltip')}>
              {(tooltip) => (
                <span {...tooltip}>
                  <InfoIcon className="h-5 w-5 text-black" />
                </span>
              )}
            </Tooltip>
          </span>
        }
      />
    ),
    [validateOrderDisabled, validatorsHaveOrder, t],
  );

  useEffect(() => {
    if (validateOrderDisabled) {
      setValidatorsHaveOrder(false);
    }
  }, [validateOrderDisabled]);

  const approverOrderDisabled = useMemo(() => users.filter((x) => x.role === ClientFormUserRole.Approver && x.requiresAction).length < 2, [users]);
  const approverFooter = useMemo(
    () => (
      <Checkbox
        disabled={approverOrderDisabled}
        value={approversHaveOrder}
        onChange={(value) => setApproversHaveOrder(value)}
        label={
          <span>
            {t('permissions-modal.group.require-ordering-approvers')}{' '}
            <Tooltip text={t('permissions-modal.group.require-ordering-approvers-tooltip')}>
              {(tooltip) => (
                <span {...tooltip}>
                  <InfoIcon className="h-5 w-5 text-black" />
                </span>
              )}
            </Tooltip>
          </span>
        }
      />
    ),
    [approverOrderDisabled, approversHaveOrder, t],
  );

  useEffect(() => {
    if (approverOrderDisabled) {
      setApproversHaveOrder(false);
    }
  }, [approverOrderDisabled]);

  const userGroups = useMemo(() => {
    return userGroupOrder
      .map((role) => {
        const roleUsers = users
          .sort((a, b) => {
            // Sort on `RequiredAction` first (true above false), inside the required=true group sort on `sortOrder`,
            // otherwise sort on `fullName`
            if (a.requiresAction !== b.requiresAction) {
              return a.requiresAction ? -1 : 1;
            }

            if (a.requiresAction && b.requiresAction) {
              return a.sortOrder! - b.sortOrder!;
            }

            return a.fullName?.localeCompare(b.fullName ?? '') ?? 0;
          })
          .filter((x) => x.role === role);

        const isRequiredRole = requiredRoles.includes(role);

        // Hide group when not required
        if (!isRequiredRole && ([ClientFormUserRole.Validator, ClientFormUserRole.Approver] as ClientFormUserRoleValues[]).includes(role)) {
          return null;
        }
        if (role === ClientFormUserRole.Owner && formStepId) {
          return null;
        }

        const warning = isRequiredRole && roleUsers.length === 0 ? t('permissions-modal.group.tooltips.required-role') : null;
        const emptyText = isRequiredRole && roleUsers.length === 0 ? t('permissions-modal.group.placeholders.unassigned-required') : null;
        const disabled = disableAdd || (role === ClientFormUserRole.Owner && roleUsers.length > 0);
        let footer = null;

        let orderingEnabled = false;
        if (role === ClientFormUserRole.Owner) {
          footer = ownerFooter;
        } else if (role === ClientFormUserRole.Validator) {
          footer = validatorFooter;
          orderingEnabled = validatorsHaveOrder;
        } else if (role === ClientFormUserRole.Approver) {
          footer = approverFooter;
          orderingEnabled = approversHaveOrder;
        }

        const filteredUsers = roleUsers.filter((x) => {
          if (!userSearchDebounced) return true;

          const search = userSearchDebounced.toLowerCase();

          const fullName = `${x.firstName ?? ''} ${x.lastName ?? ''}`.toLowerCase();
          return x.firstName?.toLowerCase().includes(search) || x.lastName?.toLowerCase().includes(search) || fullName.includes(search);
        });

        return {
          role,
          users: filteredUsers,
          emptyText,
          footer,
          warning,
          disabled,
          requiredToggle: ([ClientFormUserRole.Validator, ClientFormUserRole.Approver] as ClientFormUserRoleValues[]).includes(role),
          orderingEnabled,
        };
      })
      .filter(Boolean);
  }, [
    approverFooter,
    approversHaveOrder,
    disableAdd,
    formStepId,
    ownerFooter,
    requiredRoles,
    t,
    userSearchDebounced,
    users,
    validatorFooter,
    validatorsHaveOrder,
  ]);

  // BE doesn't allow null values for `requiresAction` for Owners, Approvers, or Validators
  useEffect(() => {
    const rolesToCheck = [ClientFormUserRole.Owner, ClientFormUserRole.Approver, ClientFormUserRole.Validator] as ClientFormUserRoleValues[];

    const shouldUpdate = users.some((x) => typeof x.requiresAction !== 'boolean' && rolesToCheck.includes(x.role));
    if (shouldUpdate) {
      onUsersChange(
        users.map((x) => {
          return {
            ...x,
            requiresAction: rolesToCheck.includes(x.role) ? !!x.requiresAction : null,
          };
        }),
      );
    }
  }, [onUsersChange, users]);

  const onUserAdd = useCallback(
    (user: ClientFormUser) => {
      onUsersChange([...users, user]);
    },
    [onUsersChange, users],
  );

  const onUserRemove = useCallback(
    (user: ClientFormUser) => {
      const roleIndexes: Record<number, number> = {};

      onUsersChange(
        users
          .filter((x) => !(x.id === user.id && x.role === user.role))
          .map((x) => {
            roleIndexes[x.role] ??= 0;

            return { ...x, sortOrder: x.requiresAction ? roleIndexes[x.role]++ : null };
          }),
      );
    },
    [onUsersChange, users],
  );

  const searchNotFound = useMemo(() => {
    return !!userSearch && userGroups.every((x) => x.users.length === 0);
  }, [userGroups, userSearch]);

  if (!inviteMode) {
    return (
      <>
        <SearchInput
          value={userSearch}
          onChange={(e) => setUserSearch(e.target.value)}
          style={InputStyle.MINIMAL}
          placeholder={t('add-or-invite-modal.add.search')}
        />

        {onCreatorRoleChange && (
          <div data-cy={`form-creator`} className="mt-3 flex items-center rounded-[4px] border px-4 py-1">
            <div className="mr-4 font-medium text-black">Creator</div>
            <div className="ml-auto flex w-56 items-center">
              <DropdownSelect
                className="w-full min-w-40"
                data-cy={`form-user-creator-role-options`}
                value={findRole(creatorRole || noAccessValue)}
                onChange={(data) => onCreatorRoleChange(data.value === noAccessValue ? null : (data.value as ClientFormUserRoleValues))}
                options={filteredRoles}
                placeholder={t('common:permissions-modal.role-placeholder')}
                aria-label={t('common:permissions-modal.role-placeholder')}
                disabled={filteredRoles.length == 1}
                size={DropdownSize.S}
              ></DropdownSelect>
            </div>
          </div>
        )}

        {userGroups.map(
          (x) =>
            (!userSearch || x.users.length > 0) && (
              <ClientFormRoleGroup
                key={x.role}
                warning={x.warning}
                role={x.role}
                users={x.users}
                disabled={x.disabled}
                onUserRemove={onUserRemove}
                onUserAdd={onUserAdd}
                requiredToggle={x.requiredToggle}
                onUserChange={onUserChange}
                enableOrdering={x.orderingEnabled}
                onUsersReorder={onUsersChange}
              >
                {x.emptyText && <ClientFormRoleGroup.Slot name="empty">{x.emptyText}</ClientFormRoleGroup.Slot>}
                <ClientFormRoleGroup.Slot name="footer">{x.footer}</ClientFormRoleGroup.Slot>
              </ClientFormRoleGroup>
            ),
        )}

        {searchNotFound && (
          <div className="mt-20 flex flex-col items-center justify-center gap-3 text-center">
            <UsersGroup2Icon className="bg-gray-6 text-gray-1 h-24 w-24 rounded-[8px] p-5" />
            <span className="text-dpm-20 font-medium text-black">{t('permissions.no-users-found')}</span>
            <span className="text-gray-1">{t('permissions.no-users-found-subline')}</span>
          </div>
        )}
      </>
    );
  } else {
    return (
      <div data-cy="add-users-mode">
        <InviteOrSearchContent
          inviteMode={true}
          filterRoles={inviteRoleFilter}
          onInputsValid={(value) => onUserInviteValid && onUserInviteValid(value)}
          onUserChange={(value) => onUserInviteChange && onUserInviteChange(value)}
          formRoles={filteredRoles.filter((x) => x.value !== noAccessValue)}
          selectedFormRole={findRole(role)}
          onFormRoleChange={(data) => onRoleChange(data.value as ClientFormUserRoleValues)}
          clientId={currentClient?.id}
        />
      </div>
    );
  }
};

export default AccessControl;
